import React from "react";
import styled, { css } from "styled-components";
import Container from "components/container/Container";

const StyledSection = styled.section`
  padding: ${({ padding }) => padding || "22px 0"};
  background-color: ${({ bgColor }) => bgColor || "transparent"};
`;

const Body = styled.div``;

const Section = ({ children, padding, bgColor, fluid = false }) => {
  return (
    <StyledSection padding={padding} bgColor={bgColor}>
      {!fluid ? (
        <Container>
          <Body>{children}</Body>
        </Container>
      ) : (
        <Body>{children}</Body>
      )}
    </StyledSection>
  );
};

export default Section;
