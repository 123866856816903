import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/auth/selectors";
import { selectTerms } from "store/terms/selectors";
import { selectMessages } from "store/messages/selectors";
import Window from "components/window/Window";
import { getStorageWithExpiry } from "utils/helpers/storageWithExpiry.helpers";
import { fetchLogin } from "store/auth/actions";
import { fetchFilterDirectories, fetchRestartTerm } from "store/terms/actions";
import ModalLogin from "components/modals/ModalLogin";
import ModalRegister from "components/modals/ModalRegister";
import ModalTerm from "components/modals/ModalTerm";
import ModalDirectory from "components/modals/ModalDirectory";
import ModalMessages from "components/modals/ModalMessages";
import { restartMessage } from "store/messages/actions";
import { fetchRestartDirectory } from "store/directories/actions";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const dataAuth = useSelector(selectAuth);
  const dataTerms = useSelector(selectTerms);

  const dataMsg = useSelector(selectMessages);

  const [isLogin, setIsLogin] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [modifyTerm, setModifyTerm] = useState(false);
  const [createDirectory, setCreateDirectory] = useState(false);

  useEffect(() => {
    const user = getStorageWithExpiry("user");
    if (user) {
      dispatch(fetchLogin({ username: null, password: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Load directories only for auth user
   */
  useEffect(() => {
    if (dataAuth.loginSuccess) {
      dispatch(fetchFilterDirectories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAuth.loginSuccess]);

  /*
   * Open window for edit current term
   */
  useEffect(() => {
    if (dataTerms.currentTerm) {
      setModifyTerm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTerms.currentTerm]);

  /*
   * Restart directory 
   */
  useEffect(() => {
    if (dataMsg.type === "Directory") {
      dispatch(fetchRestartDirectory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMsg.isModalActive, dataMsg.type]);
  return (
    <>
      <Page onClick={(e) => e.stopPropagation()}>
        <Header setIsLogin={setIsLogin} setIsRegister={setIsRegister} />
        {children}
        <Footer
          setModifyTerm={setModifyTerm}
          setCreateDirectory={setCreateDirectory}
        />
      </Page>

      <>
        <Window isActive={isLogin} handleClose={() => setIsLogin(false)}>
          <ModalLogin handleClose={() => setIsLogin(false)} />
        </Window>
        <Window isActive={isRegister} handleClose={() => setIsRegister(false)}>
          <ModalRegister handleClose={() => setIsRegister(false)} />
        </Window>
        <Window
          isActive={modifyTerm}
          handleClose={() => {
            dispatch(fetchRestartTerm());
            setModifyTerm(false);
          }}
        >
          <ModalTerm
            isActive={modifyTerm}
            handleClose={() => {
              dispatch(fetchRestartTerm());
              setModifyTerm(false);
            }}
          />
        </Window>
        <Window
          isActive={createDirectory}
          handleClose={() => {
            setCreateDirectory(false);
          }}
        >
          <ModalDirectory handleClose={() => setCreateDirectory(false)} />
        </Window>
        <Window
          isActive={dataMsg.isModalActive}
          handleClose={() => dispatch(restartMessage())}
        >
          <ModalMessages message={dataMsg.message} />
        </Window>
      </>
    </>
  );
};

export default Layout;
