import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: block;
  min-width: ${({ width }) => width || "93px"};
  padding: ${({ padding }) => padding || "10px 5px"};
  margin: ${({ margin }) => margin || "0"};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.primary};
  border: 2px solid transparent;
  border-radius: 5px;
  font-weight: 600;
  font-size: ${({ fz }) => fz || "14px;"};
  color: ${({ textColor, theme }) => textColor || theme.colors.white};
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: ${({ hoverBorderColor, theme }) => hoverBorderColor || theme.colors.primary};
    color: ${({ hoverTextColor, theme }) => hoverTextColor || theme.colors.primary};
  }
`;

const ButtonDefault = ({
  onClick,
  children,
  width,
  padding,
  margin,
  backgroundColor,
  textColor,
  hoverBorderColor,
  hoverTextColor,
  fz,
}) => {
  return (
    <StyledButton
      type='button'
      onClick={onClick}
      width={width}
      padding={padding}
      margin={margin}
      backgroundColor={backgroundColor}
      textColor={textColor}
      hoverBorderColor={hoverBorderColor}
      hoverTextColor={hoverTextColor}
      fz={fz}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonDefault;
