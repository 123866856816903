import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "components/container/Container";
import { Link, useLocation } from "react-router-dom";
import { fetchLogout } from "store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import ButtonDefault from "components/buttons/ButtonDefault";
import Icon from "components/icon/Icon";

import logo_src from "images/logo.png";
import { selectAuth } from "store/auth/selectors";
import { useTranslation } from "react-i18next";
import { Select } from "components/forms";
import { langData } from "utils/constants/serviceData.constants";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const StyledHeader = styled.header``;

const TopBar = styled.div`
  padding: 28px 0;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  position: relative;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageLink = styled(Link)`
  display: block;
`;

const ImageLogo = styled.img``;

const HomeLink = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div``;

const Navigation = styled.div``;

const LoginButton = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  ${LoginButton} {
    margin-right: 20px;
  }
  @media ${({ theme }) => theme.media.sm} {
    flex-direction: column;
    ${LoginButton} {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`;

const BottomBar = styled.div`
  padding: 12px 0;
  background-color: ${({ theme }) => theme.colors.grey};
`;

const LangBar = styled.div`
  padding: 40px 0 20px;
`;

const LangWrapper = styled.div`
  max-width: 92px;
  width: 100px;
  margin: 0 auto;
`;

const Header = ({ setIsLogin, setIsRegister }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dataAuth = useSelector(selectAuth);

  const { t, i18n } = useTranslation();

  let indexLang = langData.findIndex((el) => el.value === i18n.language);

  const [lang, setLang] = useState(langData[indexLang]);

  useEffect(() => {
    i18n.changeLanguage(lang?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  return (
    <StyledHeader>
      <TopBar>
        <Container>
          <Body>
            <ImageLink to={"/"}>
              <ImageLogo src={logo_src} alt='Terms' />
            </ImageLink>

            <HomeLink to={"/"} title={t("Titles.Home")}>
              <Icon icon='home' size='32px' color='#7856ff' />
            </HomeLink>

            <Wrapper>
              <Navigation>
                {dataAuth.userData === null ? (
                  <Buttons>
                    <LoginButton onClick={() => setIsLogin(true)}>
                      {t("Header.Buttons.Login")}
                    </LoginButton>
                    <ButtonDefault onClick={() => setIsRegister(true)}>
                      {t("Header.Buttons.Register")}
                    </ButtonDefault>
                  </Buttons>
                ) : (
                  <ButtonDefault onClick={() => dispatch(fetchLogout())}>
                    {t("Header.Buttons.Logout")}
                  </ButtonDefault>
                )}
              </Navigation>
            </Wrapper>
          </Body>
        </Container>
      </TopBar>
      {location.pathname !== "/" && (
        <BottomBar>
          <Container>
            <Breadcrumbs removeItem='detail' />
          </Container>
        </BottomBar>
      )}
      <LangBar>
        <LangWrapper>
          <Select
            bgColor='#2e2e38'
            minHeight='40px'
            lineHeight='2.5em'
            arrowTop='10px'
            options={langData}
            selectedOption={lang}
            setSelectedOption={setLang}
          />
        </LangWrapper>
      </LangBar>
    </StyledHeader>
  );
};

export default Header;
