import React from "react";
import styled from "styled-components";

const Form = styled.div`
`;

const FormContainer = ({ children }) => {
  return <Form>{children}</Form>;
};

export default FormContainer;
