import { getUnique } from "utils/helpers/getUnique.helpers";

const initialState = {
  directories: [],
  users: [],
  totalPages: 0,
  isEmptyDirectory: false,
  loading: false,
};

const directoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "directories/startLoading":
      return { ...state, loading: true };
    case "directories/loadDirectories":
      return {
        ...state,
        directories: action.payload,
        loading: false,
      };
    case "directories/loadTotalPages":
      return {
        ...state,
        totalPages: action.payload,
        loading: false,
      };
    case "directories/loadUsers":
      return { ...state, users: action.payload, loading: false };
    case "directories/loadMoreDirectories":
      return {
        ...state,
        directories: getUnique([...state.directories, ...action.payload], "id") ,
        loading: false,
      };
    case "directories/createDirectory":
      return {
        ...state,
        directories: [...state.directories, action.payload],
        loading: false,
      };
    case "directories/removeDirectory":
      const removeData = state.directories.filter((directory) => {
        return directory.id !== action.payload;
      });
      return { ...state, directories: removeData, loading: false };
    case "directories/isEmptyDirectory":
      return { ...state, isEmptyDirectory: action.payload, loading: false };
    case "directories/restartDirectory":
      return {
        ...state,
        isEmptyDirectory: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default directoriesReducer;
