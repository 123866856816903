import Layout from 'components/layout/Layout';
import React from 'react';
import MainSection from './sections/MainSection';

const ErrorPage = () => {
    return (
        <Layout>
            <MainSection/>
        </Layout>
    );
}

export default ErrorPage;