export const API_URL = "https://api.terms.pugilist.pro/wp-json";
export const PER_PAGE = "9";

export const langData = [
  {
    id: 0,
    name: "EN",
    value: "en",
  },
  {
    id: 1,
    name: "RU",
    value: "ru",
  },
  {
    id: 2,
    name: "UK",
    value: "uk",
  },
];
