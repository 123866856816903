import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/auth/selectors";
import Icon from "components/icon/Icon";
import { getStorageWithExpiry } from "utils/helpers/storageWithExpiry.helpers";
import { fetchRemoveDirectory } from "store/directories/actions";
import { useTranslation } from "react-i18next";

const Item = styled.li`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  min-height: 190px;
  background-color: ${({ theme }) => theme.colors.violet};
  color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  border-radius: 5px;
  word-break: break-all;
  @media ${({ theme }) => theme.media.md} {
    margin: 0 auto;
  }
`;

const Header = styled.div`
  margin-bottom: 15px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
`;

const Counter = styled.div`
  padding: 4px 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.violetDark};
`;

const Description = styled.div`
  margin-top: 15px;
`;

const Footer = styled.div`
  margin-top: auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const User = styled.div`
  display: flex;
`;

const UserIcon = styled.div`
  margin-right: 4px;
`;

const UserText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Remove = styled.div``;

const DirectoriesItem = ({ directory, users }) => {
  const dispatch = useDispatch();
  const dataAuth = useSelector(selectAuth);

  const { t } = useTranslation();

  const login = users.find((item) => item.id == directory?.meta?.user_id)?.name;

  const user = getStorageWithExpiry("user");
  return (
    <Item key={Math.random() + directory.id}>
      <Header>
        <HeaderWrapper>
          <StyledLink to={`/detail/${directory.id}`}>
            {directory.name}
          </StyledLink>
          <Counter>
            {directory.count} {t("HomePage.Terms")}
          </Counter>
          {directory.description && (
            <Description>{directory.description}</Description>
          )}
        </HeaderWrapper>
      </Header>
      <Footer>
        <FooterWrapper>
          <User>
            <UserIcon>
              <Icon icon='user' size='15px' color='#ff7558' />
            </UserIcon>
            <UserText>{login}</UserText>
          </User>
          {dataAuth.userData !== null &&
            directory?.meta?.user_id == user?.user_id && (
              <Remove onClick={() => dispatch(fetchRemoveDirectory(directory))}>
                <Icon icon='remove' size='15px' color='#ff7558' />
              </Remove>
            )}
        </FooterWrapper>
      </Footer>
    </Item>
  );
};

export default DirectoriesItem;
