import React from "react";
import Layout from "components/layout/Layout";
import MainSection from "./sections/mainSection/MainSection";

const HomePage = () => {
  return (
    <Layout>
      <MainSection />
    </Layout>
  );
};

export default HomePage;
