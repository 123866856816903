import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "pages/homePage/HomePage";
import DetailPage from "pages/detailPage/DetailPage";
import ErrorPage from "pages/errorPage/ErrorPage";

const Application = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/detail' element={<Navigate to="/" replace />} />
        <Route path='/detail/:id' element={<DetailPage />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Application;
