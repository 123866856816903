import React from "react";
import Section from "components/section/Section";
import TermsList from "./termsList/TermsList";
import Loader from "components/loader/Loader";
import Empty from "components/empty/Empty";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/auth/selectors";

const MainSection = ({ dataTerms }) => {
  const dataAuth = useSelector(selectAuth);
  const modifyTerms = [];

  const { t } = useTranslation();

  dataTerms.terms.forEach((item) => {
    if (!item.featured_media) modifyTerms.push(item);
    dataTerms.media.forEach((image) => {
      if (item.featured_media == image.id) {
        let modifyItem = {
          ...item,
          source_url: image.source_url,
        };
        modifyTerms.push(modifyItem);
      }
    });
  });
  return (
    <Section padding={dataAuth.userData !== null ? "20px 0 0 0" : "20px 0 40px"} fluid={true}>
      {dataTerms.loading ? (
        <Loader />
      ) : (
        <>
          {modifyTerms.length > 0 ? (
            <TermsList terms={modifyTerms} />
          ) : (
            <Empty>{t("EmptyMessage")}</Empty>
          )}
        </>
      )}
    </Section>
  );
};

export default MainSection;
