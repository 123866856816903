const initialState = {
  terms: [],
  media: [],
  filterDirectories: [],
  currentTerm: null,
  createMedia: false,
  removeMedia: false,
  loading: false,
};

const termsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "terms/startLoading":
      return { ...state, loading: true };
    case "terms/loadTerms":
      return { ...state, terms: action.payload, loading: false };
    case "terms/loadTermsMedia":
      return { ...state, media: action.payload, loading: false };
    case "terms/filterDirectories":
      return { ...state, filterDirectories: action.payload, loading: false };
    case "terms/getTerm":
      return { ...state, currentTerm: action.payload, loading: false };
    case "terms/createTerm":
      return {
        ...state,
        terms: [...state.terms, action.payload],
        loading: false,
      };
    case "terms/updateTerm":
      const updateTermData = state.terms.map((item) => {
        return item.id == action.payload.id ? action.payload : item;
      });
      return { ...state, terms: updateTermData, loading: false };
    case "terms/removeTerm":
      const removeTermData = state.terms.filter((item) => {
        return item.id !== action.payload;
      });
      return { ...state, terms: removeTermData, loading: false };
    case "terms/createMedia":
      return { ...state, media: [...state.media, action.payload], createMedia: true, loading: false };
    case "terms/removeMedia":
      const removeMediaData = state.media.filter((item) => {
        return item.id !== action.payload;
      });
      return { ...state, media: removeMediaData, removeMedia: true, loading: false };
    case "terms/restartTerms":
      return {
        ...state,
        filterDirectories: [],
        currentTerm: null,
        createMedia: false,
        removeMedia: false,
        loading: false,
      };
    case "terms/restartTerm":
      return {
        ...state,
        currentTerm: null,
        createMedia: false,
        removeMedia: false,
        loading: false,
      }
    default:
      return state;
  }
};

export default termsReducer;
