import React, { useEffect, useState } from "react";
import Section from "components/section/Section";
import DirectoriesList from "./directoriesList/DirectoriesList";
import Loader from "components/loader/Loader";
import Empty from "components/empty/Empty";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ButtonDefault from "components/buttons/ButtonDefault";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoadData, fetchLoadMore } from "store/directories/actions";
import { selectDirectories } from "store/directories/selectors";
import { sendMessage } from "store/messages/actions";

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const MainSection = () => {
  const dispatch = useDispatch();
  const incomingData = useSelector(selectDirectories);

  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchLoadData());
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(fetchLoadMore(page));
    }
  }, [page]);

  const modal = "ModalWindows.Directory";

  useEffect(() => {
    if (incomingData.isEmptyDirectory) {
      setTimeout(() => {
        dispatch(
          sendMessage({
            type: "Directory",
            message: t(`${modal}.Messages.isEmptyDirectory`),
          })
        );
      }, 1000);
    }
  }, [incomingData.isEmptyDirectory]);

  const loadMore = () => {
    setPage((page) => page + 1);
  };
  return (
    <Section padding={"40px 0"}>
      {incomingData.directories.length > 0 ? (
        <DirectoriesList
          directories={incomingData.directories}
          users={incomingData.users}
        />
      ) : (
        <Empty>{t("EmptyMessage")}</Empty>
      )}

      {page < incomingData.totalPages && (
        <LoadMore>
          <ButtonDefault onClick={loadMore}>
            {incomingData.loading ? "Loading..." : "LoadMore"}
          </ButtonDefault>
        </LoadMore>
      )}
    </Section>
  );
};

export default MainSection;
