import React from "react";
import styled from "styled-components";
import DirectoriesItem from "../directoriesItem/DirectoriesItem";

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 30px;
  @media ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
`;

const DirectoriesList = ({ directories, users }) => {
  return (
    <List>
      {directories.length > 0 &&
        directories.map((directory) => (
          <DirectoriesItem key={Math.random() + directory.id} directory={directory} users={users} />
        ))}
    </List>
  );
};

export default DirectoriesList;
