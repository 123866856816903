import Layout from "components/layout/Layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/auth/selectors";
import { selectTerms } from "store/terms/selectors";
import { fetchLoadDataByID, fetchRestartTerms } from "store/terms/actions";
import MainSection from "./sections/mainSection/MainSection";
import { useParams } from "react-router-dom";

const DetailPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const dataTerms = useSelector(selectTerms);
  const dataAuth = useSelector(selectAuth);

  useEffect(() => {
    dispatch(fetchLoadDataByID(id));
  }, []);

  useEffect(() => {
    if (dataAuth.logout) {
      dispatch(fetchRestartTerms());
    }
  }, [dataAuth.logout]);
  return (
    <Layout>
      <MainSection dataTerms={dataTerms} />
    </Layout>
  );
};

export default DetailPage;
