import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Textarea } from "components/forms";
import ButtonDefault from "components/buttons/ButtonDefault";

import { useDispatch, useSelector } from "react-redux";
import { fetchCreateDirectory } from "store/directories/actions";
import { sendMessage } from "store/messages/actions";
import FormContainer from "./elements/formContainer/FormContainer";
import FormTitle from "./elements/formTitle/FormTitle";
import FieldWrapp from "./elements/fieldWrapp/FieldWrapp";
import { selectDirectories } from "store/directories/selectors";
import { useTranslation } from "react-i18next";

const ModalDirectory = ({ handleClose }) => {
  const dispatch = useDispatch();
  const dataDirectories = useSelector(selectDirectories);

  const { t } = useTranslation();

  const modal = "ModalWindows.Directory";

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(`${modal}.Errors.FieldName.Required`)),
  });

  const initialValues = {
    name: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        dispatch(fetchCreateDirectory({ ...values })).then((res) => {
          setTimeout(() => {
            resetForm();
            handleClose();

            if (res) {
              dispatch(
                sendMessage({
                  type: "Directory",
                  message: `${t(`${modal}.Messages.createDirectorySuccess`)}${
                    values.name
                  } `,
                })
              );
            } else {
              dispatch(
                sendMessage({
                  type: "Directory",
                  message: t(`${modal}.Messages.createDirectoryFail`),
                })
              );
            }
          }, 500);
        });
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <FormContainer>
          <FormTitle>{t(`${modal}.Title`)}</FormTitle>

          <FieldWrapp>
            <Input
              label={t(`${modal}.InputLabels.Name`)}
              name='name'
              type='text'
              value={values.name}
              handleChange={handleChange}
              validate={touched.name}
              errorMessage={errors.name}
            />
          </FieldWrapp>

          <FieldWrapp>
            <Textarea
              label={t(`${modal}.InputLabels.Description`)}
              name='description'
              value={values.description}
              handleChange={handleChange}
              validate={touched.description}
              errorMessage={errors.description}
            />
          </FieldWrapp>

          <ButtonDefault
            onClick={handleSubmit}
            width='max-content'
            margin='40px auto 0 auto'
            padding='7px 35px'
            fz='18px'
          >
            {t(`${modal}.ButtonLabel`)}
          </ButtonDefault>
        </FormContainer>
      )}
    </Formik>
  );
};

export default ModalDirectory;
