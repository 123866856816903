import axios from "axios";
import { API_URL } from "utils/constants/serviceData.constants";
import {
  getStorageWithExpiry,
  setStorageWithExpiry,
} from "utils/helpers/storageWithExpiry.helpers";

const startLoadingAction = () => {
  return {
    type: "auth/startLoading",
  };
};

const getUserDataAction = (payload) => {
  return {
    type: "auth/getUserData",
    payload,
  };
};

const loginSuccessAction = (payload) => {
  return {
    type: "auth/loginSuccess",
    payload,
  };
};

const registerSuccessAction = (payload) => {
  return {
    type: "auth/registerSuccess",
    payload,
  };
};

const logoutAction = () => {
  return {
    type: "auth/logout",
  };
};

export const fetchLogin =
  ({ username, password }) =>
  async (dispatch) => {
    const userLocal = getStorageWithExpiry("user");
    if (userLocal) {
      dispatch(getUserDataAction(userLocal));
      dispatch(loginSuccessAction(true));
      return true;
    } else {
      dispatch(startLoadingAction());
      try {
        const response = await axios({
          method: "POST",
          url: API_URL + "/jwt-auth/v1/token",
          data: {
            username,
            password,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        const userData = await response.data;
        setStorageWithExpiry("user", userData, 24 * 60 * 1000);
        dispatch(getUserDataAction(userData));
        dispatch(loginSuccessAction(true));
        return true;
      } catch (error) {
        console.error(error.message);
        dispatch(loginSuccessAction(false));
        return false;
      }
    }
  };

export const fetchRegister =
  ({ email, username, password }) =>
  async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      axios({
        method: "POST",
        url: API_URL + "/routes/v1/users/register",
        data: {
          email,
          username,
          password,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(registerSuccessAction(true));
      return true;
    } catch (error) {
      console.error(error.message);
      dispatch(registerSuccessAction(false));
      return false;
    }
  };

export const fetchLogout = () => async (dispatch) => {
  localStorage.removeItem("user");
  dispatch(logoutAction());
};
