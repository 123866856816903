import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Application from "Application";
import { GlobalStyles, TermsTheme } from "ThemeSettings";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import './i18n';
import store from "store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={TermsTheme}>
        <GlobalStyles />
        <Provider store={store}>
          <Application />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
