import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  visibility: ${({ $isActive }) => ($isActive ? "visible" : "hidden")};
  background: rgba(32, 31, 36, 0.6);
  backdrop-filter: blur(7px);
  padding: 10px;
  transition: all 0.5s ease;
  z-index: 99999;

  @media ${({theme}) => theme.media.sm} {
    padding: 0;
  }
`;

const Content = styled.div``;

const Body = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 40px 30px;
  background: ${({ theme }) => theme.colors.violet};
  border-radius: 5px;
  position: relative;
  overflow-y: auto;
`;

const CloseButton = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -7.5px;
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`;

const Window = ({ children, isActive, handleClose }) => {
  return (
    <Wrapper $isActive={isActive} onClick={handleClose}>
      <Body onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={handleClose}></CloseButton>
        <Content>{children}</Content>
      </Body>
    </Wrapper>
  );
};

export default Window;
