import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    background-color: #201f24;
  }

  p {
	  margin: 0;
  }

  ul, li {
    display: block;
    padding: 0;
    margin: 0;
  }

  a {
    display: inline-block;
    line-height: 0.9em;
    text-decoration: none;
    color: #ff7558;
  }

  a, button {
    box-sizing: border-box;
    transition: all .5s ease;
  }

  input, input:hover, input:active, input:focus, 
  textarea, textarea:hover, textarea:active, textarea:focus,
  button, button:hover, button:active, button:focus, 
  a:hover, a:active, a:focus {
    outline: none;
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.eot');
  src: url('./fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/Montserrat-Medium.woff') format('woff'),
    url('./fonts/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Light.eot');
  src: url('./fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Light.woff2') format('woff2'),
    url('./fonts/Montserrat-Light.woff') format('woff'),
    url('./fonts/Montserrat-Light.ttf') format('truetype'),
    url('./fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.eot');
  src: url('./fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./fonts/Montserrat-Regular.woff') format('woff'),
    url('./fonts/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.eot');
  src: url('./fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./fonts/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.eot');
  src: url('./fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./fonts/Montserrat-Bold.woff') format('woff'),
    url('./fonts/Montserrat-Bold.ttf') format('truetype'),
    url('./fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
`;

export const TermsTheme = {
  colors: {
    primary: "#7856ff",
    secondary: "#ff7558",
    grey: "#626164",
    violet: "#2e2e38",
    violetDark: "#201f24",
    white: "#ffffff",
    black: "#171719",
    red: "#ed4b4b",
  },
  media: {
    sm: "(max-width: 575.98px)",
    md: "(max-width: 767.98px)",
    lg: "(max-width: 991.98px)",
    xl: "(max-width: 1199.98px)",
    xxl: "(max-width: 1399.98px)",
  },
};
