import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import authReducer from "./auth/reducer";
import directoriesReducer from "./directories/reducer";
import termsReducer from "./terms/reducer";
import messagesReducer from "./messages/reducer";
import { thunk } from "redux-thunk";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      })
    : compose;

const rootReducer = combineReducers({
  authReducer,
  directoriesReducer,
  termsReducer,
  messagesReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
export default store;
