import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Container from "components/container/Container";
import Icon from "components/icon/Icon";
import { selectAuth } from "store/auth/selectors";
import { fetchRestartTerm } from "store/terms/actions";
import styled from "styled-components";
import IconHart from "components/iconComponents/IconHart";
import { selectTerms } from "store/terms/selectors";
import { selectDirectories } from "store/directories/selectors";
import { useTranslation } from "react-i18next";

const StyledFooter = styled.footer`
  margin-top: auto;
`;

const FooterNavigation = styled.div`
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
`;

const CreateButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomBar = styled.div`
  padding: 22px 0;
  color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  position: relative;
`;

const Body = styled.div``;

const Copyright = styled.div`
  text-align: center;
`;

const Footer = ({ setModifyTerm, setCreateDirectory }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const dataAuth = useSelector(selectAuth);
  const dataTerms = useSelector(selectTerms);
  const dataDirectories = useSelector(selectDirectories);

  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      {dataAuth.userData !== null &&
        (!dataTerms.loading || dataDirectories.loading) && (
          <FooterNavigation>
            <Container>
              {location.pathname.indexOf("/detail/") !== -1 && (
                <CreateButton
                  onClick={() => {
                    dispatch(fetchRestartTerm());
                    setModifyTerm(true);
                  }}
                  title={t("Titles.CreateTerm")}
                >
                  <Icon icon='plus' size='33px' color='#ffffff' />
                </CreateButton>
              )}
              {location.pathname === "/" && (
                <CreateButton
                  onClick={() => setCreateDirectory(true)}
                  title={t("Titles.CreateDirectory")}
                >
                  <Icon icon='plus' size='33px' color='#ffffff' />
                </CreateButton>
              )}
            </Container>
          </FooterNavigation>
        )}
      <BottomBar>
        <Container>
          <Body>
            <Copyright>
              © {year} created with <IconHart /> by Miler
            </Copyright>
          </Body>
        </Container>
      </BottomBar>
    </StyledFooter>
  );
};

export default Footer;
