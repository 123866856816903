import React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const bounceAnimation = keyframes`
    0% { margin-bottom: 0; }
    50% { margin-bottom: 15px }
    100% { margin-bottom: 0 }
`;

const Dots = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Dot = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  /* Animation */
  animation: ${bounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

const Loader = () => {
  return (
    <Wrapper>
      <Dots>
        <Dot delay='0s' />
        <Dot delay='.2s' />
        <Dot delay='.5s' />
      </Dots>
    </Wrapper>
  );
};
export default Loader;
