import Icon from "components/icon/Icon";
import { useRef, useState } from "react";
import styled from "styled-components";
import useOutsideClick from "utils/helpers/useOutsideClick.helpers";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Label = styled.span`
  display: block;
  font-weight: 400;
  font-size: ${({ lfz }) => lfz || "16px"};
  margin-bottom: ${({ lmb }) => lmb || "5px"};
  color: ${({ lc, theme }) => lc || theme.colors.white};
`;

const SelectContainer = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
`;

const SelectHeader = styled.div`
  background-color: ${({ $bgColor, theme }) =>
    $bgColor || theme.colors.violetDark};
  box-shadow: ${({ $isValid }) =>
    $isValid ? "none" : "inset 0px 0px 10px rgba(255, 0, 0, 0.7)"};
  padding: 0 20px;
  min-height: ${({ $minHeight }) => $minHeight || "45px"};
  border-radius: 5px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey};
`;

const SelectText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: ${({ $lineHeight }) => $lineHeight || "2.7em"};
`;

const SelectArrow = styled(Icon)`
  transition: transform 0.5s ease;
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(90deg)" : "rotate(-90deg)")};
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: ${({ $arrowTop }) => $arrowTop || "14px"};
`;

const SelectListContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  z-index: 2;
`;

const SelectList = styled.ul`
  width: 100%;
  max-height: 148px;
  overflow-y: auto;
  background-color: ${({ $bgColor, theme }) =>
    $bgColor || theme.colors.violetDark};
  border-radius: 5px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;

const SelectItem = styled.li`
  padding: 0 20px;
  transition: all 0.5s ease;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const HelpText = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 8px;
  line-height: 1.1em;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 2px;
`;

export const Select = ({
  label,
  holder =  "Choose Item",
  options = [],
  selectedOption,
  setSelectedOption,

  errorMessage,
  validate,

  bgColor,
  minHeight,
  lineHeight,
  arrowTop,

  lfz,
  lmb,
  lc,
}) => {
  //hooks
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  //functions
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  return (
    <Wrapper ref={ref}>
      {label && (
        <Label lfz={lfz} lmb={lmb} lc={lc}>
          {label}
        </Label>
      )}

      <SelectContainer>
        <SelectHeader
          onClick={toggling}
          $isOpen={isOpen}
          $isValid={!(errorMessage && validate)}
          $bgColor={bgColor}
          $minHeight={minHeight}
        >
          <SelectText $lineHeight={lineHeight}>
            {selectedOption ? selectedOption?.name : holder}
          </SelectText>
          <SelectArrow
            icon='arrow-left'
            size='18px'
            color='#626164'
            $arrowTop={arrowTop}
            $isOpen={isOpen}
          ></SelectArrow>
        </SelectHeader>
        {isOpen && (
          <SelectListContainer>
            <SelectList $bgColor={bgColor}>
              {options &&
                options.map((option, i) => (
                  <SelectItem
                    key={i + Math.random()}
                    onClick={onOptionClicked(option)}
                  >
                    <SelectText $lineHeight={lineHeight}>
                      {option?.name}
                    </SelectText>
                  </SelectItem>
                ))}
            </SelectList>
          </SelectListContainer>
        )}
      </SelectContainer>
      {errorMessage && validate ? <HelpText>{errorMessage}</HelpText> : <></>}
    </Wrapper>
  );
};
