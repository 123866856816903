import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import Icon from "components/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/auth/selectors";
import { fetchGetTerm, fetchRemoveTerm } from "store/terms/actions";
import { getStorageWithExpiry } from "utils/helpers/storageWithExpiry.helpers";
import { CSSTransition } from "react-transition-group";

const Item = styled.div`
  perspective: 1000px;
`;

const InnerContainer = styled.div`
  max-width: 800px;
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform-style: preserve-3d;
  transition: 1s;
  margin: 0 auto;

  &.flip-exit {
    transform: rotateY(0deg);
  }

  &.flip-exit-active {
    transform: rotateY(180deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  &.flip-exit-done {
    transform: rotateY(180deg);
  }

  &.flip-enter {
    transform: rotateY(180deg);
  }

  &.flip-enter-active {
    transform: rotateY(0deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  &.flip-enter-done {
    transform: rotateY(0deg);
  }
`;

const sideStyles = css`
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.violet};
  color: ${({ theme }) => theme.colors.white};
  font-size: 26px;
  text-align: center;
  word-break: break-all;
  backface-visibility: hidden;
`;

const Front = styled.div`
  ${sideStyles};
`;

const Back = styled.div`
  ${sideStyles};
  transform: rotateY(180deg);
`;

const Navigation = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
`;

const Remove = styled.div``;

const Edit = styled.div`
  margin-right: 8px;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 24px;
  margin: 0 auto;
`;

const ImageWrapp = styled.div`
  max-width: 290px;
  max-height: 290px;
  border-radius: 5px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $isImage }) =>
    $isImage
      ? css`
          ${Text},
          ${ImageWrapp} {
            width: 48%;
            @media ${({ theme }) => theme.media.md} {
              width: 100%;
            }
          }
          @media ${({ theme }) => theme.media.md} {
            flex-direction: column;
            align-items: center;
            ${Text} {
              margin-bottom: 40px;
            }
            ${ImageWrapp} {
              max-width: 260px;
              max-height: 260px;
            }
          }
        `
      : css`
          ${Text} {
            width: 100%;
          }
        `}
`;

const TermsItem = ({ term }) => {
  const dispatch = useDispatch();
  const dataAuth = useSelector(selectAuth);

  const user = getStorageWithExpiry("user");

  const nodeRef = useRef(null);
  const [isFlipped, setIsFlipped] = useState(true);
  return (
    <Item>
      <CSSTransition
        nodeRef={nodeRef}
        in={isFlipped}
        timeout={300}
        classNames='flip'
      >
        <InnerContainer
          ref={nodeRef}
          onClick={() => {
            setIsFlipped((v) => !v)
          }}
        >
          <Front>
            <Text dangerouslySetInnerHTML={{ __html: term.title.rendered }} />
          </Front>
          <Back>
            <Wrapper $isImage={term.source_url ? true : false}>
              <Text
                dangerouslySetInnerHTML={{ __html: term.content.rendered }}
              />
              {term.source_url && (
                <ImageWrapp>
                  <Image src={term.source_url} alt={term.title.rendered} />
                </ImageWrapp>
              )}
              {dataAuth.userData !== null && term.author === user?.user_id && (
                <Navigation>
                  <Edit onClick={() => dispatch(fetchGetTerm(term.id))}>
                    <Icon icon='edit' color={"#fff"} size='18px' />
                  </Edit>
                  <Remove onClick={() => dispatch(fetchRemoveTerm(term))}>
                    <Icon icon='remove' color={"#fff"} size='18px' />
                  </Remove>
                </Navigation>
              )}
            </Wrapper>
          </Back>
        </InnerContainer>
      </CSSTransition>
    </Item>
  );
};

export default TermsItem;
