import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const List = styled.ul`
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  &::after {
    content: "";
    display: inline-block;
    height: 12px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    margin: 0 8px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  transition: all .5s ease;
  &:hover {
    border-color: transparent;
  }
`;

const Breadcrumbs = ({ homeLink = true, removeItem = "" }) => {
  const location = useLocation();

  let crumbsArray = location.pathname
    .split("/")
    .filter((path) => path !== "")
    .map((crumb) => {
      return {
        link: `/${crumb}`,
        value: crumb[0].toUpperCase() + crumb.slice(1),
      };
    });

  if (homeLink) crumbsArray = [{ link: "/", value: "Home" }, ...crumbsArray];
  if (removeItem) {
    const index = crumbsArray.findIndex(
      (item) => item.value.toLowerCase() == removeItem
    );
    if (index !== -1) crumbsArray.splice(index, 1);
  }
  return (
    <Wrapper>
      <List>
        {crumbsArray.map((crumb, index) => {
          return index !== crumbsArray.length - 1 ? (
            <Item key={Math.random() + index}>
              <StyledLink to={crumb.link}>{crumb.value}</StyledLink>
            </Item>
          ) : (
            <Item key={Math.random() + index}>{crumb.value}</Item>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default Breadcrumbs;
