import axios from "axios";
import { API_URL, PER_PAGE } from "utils/constants/serviceData.constants";
import { getStorageWithExpiry } from "utils/helpers/storageWithExpiry.helpers";

const startLoadingAction = () => {
  return {
    type: "directories/startLoading",
  };
};

const loadDirectoriesAction = (payload) => {
  return {
    type: "directories/loadDirectories",
    payload,
  };
};

const loadMoreDirectoriesAction = (payload) => {
  return {
    type: "directories/loadMoreDirectories",
    payload,
  };
};

const loadUsersAction = (payload) => {
  return {
    type: "directories/loadUsers",
    payload,
  };
};

const loadTotalPagesAction = (payload) => {
  return {
    type: "directories/loadTotalPages",
    payload,
  };
};

const createDirectoryAction = (payload) => {
  return {
    type: "directories/createDirectory",
    payload,
  };
};

export const removeDirectoryAction = (payload) => {
  return {
    type: "directories/removeDirectory",
    payload,
  };
};

const isEmptyDirectoryAction = (payload) => {
  return {
    type: "directories/isEmptyDirectory",
    payload,
  };
};

const restartDirectoryAction = () => {
  return {
    type: "directories/restartDirectory",
  };
};

export const fetchLoadData = () => async (dispatch) => {
  dispatch(startLoadingAction());
  try {
    const response = await Promise.all([
      axios.get(API_URL + "/wp/v2/directories?per_page=" + PER_PAGE),
      axios.get(API_URL + "/wp/v2/users"),
    ]);
    const directories = response[0].data;
    const totalPages = response[0].headers["x-wp-totalpages"];
    const users = response[1].data;

    dispatch(loadDirectoriesAction(directories));
    dispatch(loadTotalPagesAction(totalPages));
    dispatch(loadUsersAction(users));
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchLoadMore = (page) => async (dispatch) => {
  dispatch(startLoadingAction());
  try {
    const response = await axios.get(
      API_URL + "/wp/v2/directories?page=" + page + "&per_page=" + PER_PAGE
    );
    const directories = response.data;
    const totalPages = response.headers["x-wp-totalpages"];

    dispatch(loadMoreDirectoriesAction(directories));
    dispatch(loadTotalPagesAction(totalPages));
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchCreateDirectory =
  ({ name, description = "" }) =>
  async (dispatch) => {
    const user = getStorageWithExpiry("user");

    if (user) {
      dispatch(startLoadingAction());
      try {
        const response = await axios({
          method: "POST",
          url: API_URL + "/wp/v2/directories",
          data: {
            name,
            description,
            meta: {
              user_id: user?.user_id.toString(),
            },
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const directory = await response.data;
        dispatch(createDirectoryAction(directory));
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
  };

export const fetchRemoveDirectory = (obj) => async (dispatch) => {
  const user = getStorageWithExpiry("user");

  if (user) {
    if (obj.count == 0) {
      dispatch(startLoadingAction());
      try {
        const response = await axios({
          method: "DELETE",
          url: API_URL + "/wp/v2/directories/" + obj.id + "?force=true",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const { previous } = await response.data;
        dispatch(removeDirectoryAction(previous.id));
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    } else {
      dispatch(isEmptyDirectoryAction(true));
    }
  }
};

export const fetchRestartDirectory = () => async (dispatch) => {
  dispatch(restartDirectoryAction());
};
