import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Empty = ({children}) =>  {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
}

export default Empty;