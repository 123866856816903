import React from "react";
import styled from "styled-components";

const Title = styled.h4`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
`;

const FormTitle = ({ children }) => {
  return <Title>{children}</Title>;
};

export default FormTitle;