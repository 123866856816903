import axios from "axios";
import { API_URL } from "utils/constants/serviceData.constants";
import { getStorageWithExpiry } from "utils/helpers/storageWithExpiry.helpers";

const startLoadingAction = () => {
  return {
    type: "terms/startLoading",
  };
};

const loadTermsAction = (payload) => {
  return {
    type: "terms/loadTerms",
    payload,
  };
};

const loadTermsMediaAction = (payload) => {
  return {
    type: "terms/loadTermsMedia",
    payload,
  };
};

const getTermAction = (payload) => {
  return {
    type: "terms/getTerm",
    payload,
  };
};

const filterDirectoriesAction = (payload) => {
  return {
    type: "terms/filterDirectories",
    payload,
  };
};

const createTermAction = (payload) => {
  return {
    type: "terms/createTerm",
    payload,
  };
};

const updateTermAction = (payload) => {
  return {
    type: "terms/updateTerm",
    payload,
  };
};

const createMediaAction = (payload) => {
  return {
    type: "terms/createMedia",
    payload,
  };
};

const removeMediaAction = (payload) => {
  return {
    type: "terms/removeMedia",
    payload,
  };
};

const removeTermAction = (payload) => {
  return {
    type: "terms/removeTerm",
    payload,
  };
};

const restartTermsAction = () => {
  return {
    type: "terms/restartTerms",
  };
};

const restartTermAction = () => {
  return {
    type: "terms/restartTerm",
  };
};

export const fetchLoadDataByID = (id) => async (dispatch) => {
  dispatch(startLoadingAction());
  try {
    const response = await Promise.all([
      axios.get(API_URL + "/wp/v2/terms?directories=" + id),
      axios.get(API_URL + "/wp/v2/media"),
    ]);
    const terms = response[0].data;
    const media = response[1].data;
    dispatch(loadTermsAction(terms));
    dispatch(loadTermsMediaAction(media));
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchFilterDirectories = () => async (dispatch) => {
  const user = getStorageWithExpiry("user");

  if (user) {
    dispatch(startLoadingAction());
    try {
      const response = await axios.get(API_URL + "/wp/v2/directories");
      const directories = await response.data;
      const filterDrectories = directories.filter(
        (item) => +item?.meta?.user_id == user?.user_id
      );
      dispatch(filterDirectoriesAction(filterDrectories));
    } catch (error) {
      console.error(error.message);
    }
  }
};

export const fetchRestartTerms = () => async (dispatch) => {
  dispatch(restartTermsAction());
};

export const fetchRestartTerm = () => async (dispatch) => {
  dispatch(restartTermAction());
};

export const fetchGetTerm = (id) => async (dispatch) => {
  const user = getStorageWithExpiry("user");

  if (user) {
    try {
      const response = await axios.get(API_URL + "/wp/v2/terms/" + id);
      const terms = await response.data;
      dispatch(getTermAction(terms));
    } catch (error) {
      console.error(error.message);
    }
  }
};

export const fetchCreateTerm =
  ({ word, translate, directories, featured_image = null }) =>
  async (dispatch) => {
    const user = getStorageWithExpiry("user");
    let term = {
      title: word,
      content: translate,
      directories,
      status: "publish",
    };
    let featuredImageId = 0;

    if (featured_image) {
      featuredImageId = innerFetchCreateMedia(featured_image);
    }

    if (featuredImageId) term.featured_media = featuredImageId;

    if (user) {
      dispatch(startLoadingAction());
      try {
        const response = await axios({
          method: "POST",
          url: API_URL + "/wp/v2/terms",
          data: {
            ...term,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const termData = await response.data;
        dispatch(createTermAction(termData));
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
  };

export const fetchUpdateTerm =
  ({ word, translate, directories, currentTerm, featured_image = null }) =>
  async (dispatch) => {
    const user = getStorageWithExpiry("user");
    let term = {
      title: word,
      content: translate,
      directories,
      status: "publish",
    };
    let featuredImageId = 0;

    console.log(featured_image)

    if (typeof featured_image === "number" && featured_image > 0) {
      featuredImageId = featured_image;
    }

    if (featured_image && typeof featured_image === "object") {
      if (currentTerm.featured_media == 0) {
        const mediaData = await innerFetchCreateMedia(featured_image);
        dispatch(createMediaAction(mediaData));
        featuredImageId = mediaData.id;
      }
      if (currentTerm.featured_media > 0) {
        dispatch(fetchRemoveMedia(currentTerm.featured_media));
        const mediaData = await innerFetchCreateMedia(featured_image);
        dispatch(createMediaAction(mediaData));
        featuredImageId = mediaData.id;
      }
    }

    if (featured_image == "" && currentTerm.featured_media > 0) {
      dispatch(fetchRemoveMedia(currentTerm.featured_media));
    }

    if (featuredImageId) term.featured_media = featuredImageId;

    if (user) {
      dispatch(startLoadingAction());
      try {
        const response = await axios({
          method: "PUT",
          url: API_URL + "/wp/v2/terms/" + currentTerm.id,
          data: {
            ...term,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const updateData = await response.data;
        dispatch(updateTermAction(updateData));
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
  };

export const fetchRemoveTerm = (obj) => async (dispatch) => {
  const user = getStorageWithExpiry("user");

  if (obj.featured_media && user) {
    try {
      const response = await axios({
        method: "DELETE",
        url: API_URL + "/wp/v2/media/" + obj.featured_media + "?force=true",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const { previous } = await response.data;
      dispatch(removeMediaAction(previous.id));
    } catch (error) {
      console.error(error.message);
    }
  }

  if (user) {
    dispatch(startLoadingAction());
    try {
      const response = await axios({
        method: "DELETE",
        url: API_URL + "/wp/v2/terms/" + obj.id + "?force=true",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const { previous } = await response.data;
      dispatch(removeTermAction(previous.id));
      return true;
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }
};

export const fetchRemoveMedia = (id) => async (dispatch) => {
  const user = getStorageWithExpiry("user");

  if (user) {
    try {
      const response = await axios({
        method: "DELETE",
        url: API_URL + "/wp/v2/media/" + id + "?force=true",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const { previous } = await response.data;
      dispatch(removeMediaAction(previous.id));
    } catch (error) {
      console.error(error.message);
    }
  }
};

const innerFetchCreateMedia = async (obj) => {
  const user = getStorageWithExpiry("user");
  if (user) {
    const formData = new FormData();
    formData.append("file", obj);

    const response = await axios.post(API_URL + "/wp/v2/media", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const mediaData = await response.data;
    return mediaData;
  }
};
