import Icon from "components/icon/Icon";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectTerms } from "store/terms/selectors";
import styled, { css } from "styled-components";

const Label = styled.label`
  display: block;
  font-weight: 400;
  font-size: ${({ lfz }) => lfz || "16px"};
  line-height: 1.17em;
  margin-bottom: ${({ lmb }) => lmb || "10px"};
  color: ${({ lc, theme }) => lc || theme.colors.white};
`;

const inputStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  cursor: pointer;
`;

const GhostInput = styled.input`
  ${inputStyle}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileWrapper = styled.div`
  position: relative;
  margin-right: 20px;
`;

const FileButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const FilePreview = styled.div`
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
  max-width: 100px;
  position: relative;
  cursor: pointer;
`;

const FileRemove = styled.div`
  width: 25px;
  height: 25px;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -7.5px;
    transition: 0.5s ease;
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`;

const FileOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s ease;
  &:hover {
    ${FileRemove} {
      &::before,
      &::after {
        background-color: ${({ theme }) => theme.colors.secondary};
      }
    }
    background-color: rgba(32, 31, 36, 0.6);
  }
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
`;

const HelpText = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 8px;
  line-height: 1.1em;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 2px;
`;

export const File = ({
  id,
  label,
  name,
  handleChange = () => {},
  errorMessage,
  validate,
  value = "",
  formats,
  clearPreview,
  handleRestart,

  lfz,
  lc,
  lmb,
}) => {
  const dataTerms = useSelector(selectTerms);
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (!Number.isInteger(value)) {
      if (formats.includes(value?.type)) {
        setPreview(value);
      }
    } else {
      if (dataTerms?.media.length > 0 && value !== 0) {
        const url = dataTerms?.media.find(
          (item) => item.id == value
        ).source_url;
        setPreview(url);
      }
    }
  }, [value]);

  useEffect(() => {
    if (!clearPreview) setPreview("");
  }, [clearPreview]);

  const handelRemovePreview = () => {
    setPreview("");
    handleRestart();
  };
  return (
    <>
      {label && (
        <Label htmlFor={id} lfz={lfz} lmb={lmb} lc={lc}>
          {label}
        </Label>
      )}

      <Wrapper>
        <FileWrapper>
          <GhostInput
            id={id}
            name={name}
            type='file'
            accept={formats.join(", ")}
            onChange={handleChange}
            $isValid={!(errorMessage && validate)}
          />
          <FileButton>
            <Icon icon='plus' size='33px' color='#ffffff' />
          </FileButton>
        </FileWrapper>
        <FilePreview>
          <FileOverlay title='Remove' onClick={handelRemovePreview}>
            <FileRemove />
          </FileOverlay>
          <Image
            src={
              preview &&
              (typeof preview === "string"
                ? preview
                : URL.createObjectURL(preview))
            }
          />
        </FilePreview>
      </Wrapper>

      {errorMessage && validate ? <HelpText>{errorMessage}</HelpText> : <></>}
    </>
  );
};
