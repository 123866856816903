const IconHart = ({ w = 18, h = 15.23, fill = "#ff7558"}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox="0 0 100 84"
    width={w}
    height={h}
    fill={fill}
  >
    <path d="M49.5 83.6C49.5 83.6 1.10001 63.8 1.10001 27.7C1.00001 12.6 12.6 0.400024 26.9 0.400024C37 0.400024 45.8 6.60003 50 15.6C54.2 6.60003 63 0.400024 73.1 0.400024C87.4 0.400024 99 12.6 99 27.7C99 63.6 49.5 83.6 49.5 83.6Z"/>
  </svg>
);

export default IconHart;
