import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "components/forms";
import ButtonDefault from "components/buttons/ButtonDefault";

import { fetchLogin } from "store/auth/actions";
import { selectAuth } from "store/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "store/messages/actions";
import FormContainer from "./elements/formContainer/FormContainer";
import FormTitle from "./elements/formTitle/FormTitle";
import FieldWrapp from "./elements/fieldWrapp/FieldWrapp";
import { useTranslation } from "react-i18next";

const ModalLogin = ({ handleClose }) => {
  const dispatch = useDispatch();
  const dataAuth = useSelector(selectAuth);

  const { t } = useTranslation();

  const modal = "ModalWindows.Login";

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, t(`${modal}.Errors.FieldUsername.Min`))
      .max(25, t(`${modal}.Errors.FieldUsername.Max`))
      .required(t(`${modal}.Errors.FieldUsername.Required`)),
    password: Yup.string()
      .min(8, t(`${modal}.Errors.FieldPassword.Min`))
      .max(25, t(`${modal}.Errors.FieldPassword.Max`))
      .required(t(`${modal}.Errors.FieldPassword.Required`)),
  });

  const initialValues = {
    username: "",
    password: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        dispatch(fetchLogin({ ...values })).then((res) => {
          setTimeout(() => {
            resetForm();
            handleClose();

            if (res) {
              dispatch(
                sendMessage({
                  type: "Login",
                  message: t(`${modal}.Messages.loginSuccess`),
                })
              );
            } else {
              dispatch(
                sendMessage({
                  type: "Login",
                  message: t(`${modal}.Messages.loginFail`),
                })
              );
            }
          }, 500);
        });
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <FormContainer>
          <FormTitle>{t(`${modal}.Title`)}</FormTitle>

          <FieldWrapp>
            <Input
              label={t(`${modal}.InputLabels.Username`)}
              name='username'
              type='text'
              value={values.username}
              handleChange={handleChange}
              validate={touched.username}
              errorMessage={errors.username}
            />
          </FieldWrapp>

          <FieldWrapp>
            <Input
              label={t(`${modal}.InputLabels.Password`)}
              name='password'
              type='password'
              value={values.password}
              handleChange={handleChange}
              validate={touched.password}
              errorMessage={errors.password}
            />
          </FieldWrapp>

          <ButtonDefault
            onClick={handleSubmit}
            width='max-content'
            margin='40px auto 0 auto'
            padding='7px 35px'
            fz='18px'
          >
            {t(`${modal}.ButtonLabel`)}
          </ButtonDefault>
        </FormContainer>
      )}
    </Formik>
  );
};

export default ModalLogin;
