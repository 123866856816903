import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { File, Input, Select } from "components/forms";
import ButtonDefault from "components/buttons/ButtonDefault";

import { selectAuth } from "store/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCreateTerm,
  fetchRestartTerm,
  fetchUpdateTerm,
} from "store/terms/actions";
import { selectTerms } from "store/terms/selectors";
import FormContainer from "./elements/formContainer/FormContainer";
import FormTitle from "./elements/formTitle/FormTitle";
import FieldWrapp from "./elements/fieldWrapp/FieldWrapp";
import { useTranslation } from "react-i18next";

const ModalTerm = ({ isActive, handleClose }) => {
  const dispatch = useDispatch();
  const dataTerms = useSelector(selectTerms);

  const { t } = useTranslation();

  const modal = "ModalWindows.Term";

  const [directories, setDirectoriesOption] = useState("");

  const formikRef = useRef();

  useEffect(() => {
    if (!isActive) {
      setDirectoriesOption("");
      formikRef.current?.resetForm();
    }
  }, [isActive]);

  useEffect(() => {
    if (dataTerms.filterDirectories && dataTerms.currentTerm) {
      setDirectoriesOption(
        dataTerms.filterDirectories.find(
          (item) => item.id === dataTerms.currentTerm.directories[0]
        )
      );
    }
  }, [dataTerms.filterDirectories, dataTerms.currentTerm]);

  const formats = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

  const validationSchema = Yup.object().shape({
    word: Yup.string().required(t(`${modal}.Errors.FieldWord.Required`)),
    translate: Yup.string().required(
      t(`${modal}.Errors.FieldTranslate.Required`)
    ),
    directories: Yup.string().required(
      t(`${modal}.Errors.FieldDirectories.Required`)
    ),
  });

  const initialValues = {
    word: dataTerms.currentTerm ? dataTerms.currentTerm.title.rendered : "",
    translate: dataTerms.currentTerm
      ? dataTerms.currentTerm.content.rendered.replace(/(<([^>]+)>)/gi, "")
      : "",
    directories: dataTerms.currentTerm
      ? dataTerms.currentTerm.directories[0]
      : "",
    featured_image: dataTerms.currentTerm
      ? dataTerms.currentTerm.featured_media
      : null,
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, { resetForm }) => {
        if (dataTerms.currentTerm) {
          dispatch(
            fetchUpdateTerm({
              ...values,
              currentTerm: dataTerms.currentTerm,
            })
          );
        } else {
          dispatch(fetchCreateTerm({ ...values }));
        }

        setTimeout(() => {
          dispatch(fetchRestartTerm());
          resetForm();
          handleClose();
        }, 500);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleSubmit,
      }) => (
        <FormContainer>
          <FormTitle>{t(`${modal}.Title`)}</FormTitle>

          <FieldWrapp>
            <Select
              label={t(`${modal}.InputLabels.Directory`)}
              holder={t(`${modal}.InputLabels.Holder`)}
              selectedOption={directories}
              setSelectedOption={(e) => {
                setDirectoriesOption(e);
                setFieldValue("directories", e.id);
              }}
              options={dataTerms.filterDirectories}
              validate={touched.directories}
              errorMessage={errors.directories}
            />
          </FieldWrapp>

          <FieldWrapp>
            <Input
              label={t(`${modal}.InputLabels.Word`)}
              name='word'
              type='text'
              value={values.word}
              handleChange={handleChange}
              validate={touched.word}
              errorMessage={errors.word}
            />
          </FieldWrapp>

          <FieldWrapp>
            <Input
              label={t(`${modal}.InputLabels.Translate`)}
              name='translate'
              type='text'
              value={values.translate}
              handleChange={handleChange}
              validate={touched.translate}
              errorMessage={errors.translate}
            />
          </FieldWrapp>

          <FieldWrapp>
            <File
              label={t(`${modal}.InputLabels.Image`)}
              name='featured_image'
              formats={formats}
              clearPreview={isActive}
              handleChange={(e) => {
                setFieldValue("featured_image", e.target.files[0]);
              }}
              handleRestart={() => setFieldValue("featured_image", "")}
              value={values.featured_image}
              validate={touched.featured_image}
              errorMessage={errors.featured_image}
            />
          </FieldWrapp>

          <ButtonDefault
            onClick={handleSubmit}
            width='max-content'
            margin='40px auto 0 auto'
            padding='7px 35px'
            fz='18px'
          >
            {t(`${modal}.ButtonLabel`)}
          </ButtonDefault>
        </FormContainer>
      )}
    </Formik>
  );
};

export default ModalTerm;
