const setSendMessageAction = (payload) => {
  return {
    type: "messages/setSendMessage",
    payload,
  };
};

const setRestartMessageAction = () => {
  return {
    type: "messages/setRestartMessage",
  };
};

export const sendMessage = (msg) => async (dispatch) => {
  dispatch(setSendMessageAction(msg));
};

export const restartMessage = () => async (dispatch) => {
  dispatch(setRestartMessageAction());
};
