const initialState = {
  type: "",
  message: "",
  isModalActive: false,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "messages/setSendMessage":
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        isModalActive: true,
      };
    case "messages/setRestartMessage":
      return { ...state, type: "", message: "", isModalActive: false };
    default:
      return state;
  }
};

export default messagesReducer;
