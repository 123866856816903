import React from "react";
import styled, { css } from "styled-components";

const Label = styled.label`
  display: block;
  font-weight: 400;
  font-size: ${({ lfz }) => lfz || "16px"};
  line-height: 1em;
  margin-bottom: ${({ lmb }) => lmb || "5px"};
  color: ${({ lc, theme }) => lc || theme.colors.white};
`;

const inputStyle = css`
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || "45px"};
  padding: ${({ padding }) => padding || "6px 20px;"};
  background-color: ${({ theme }) => theme.colors.violetDark};
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  color: ${({ theme }) => theme.colors.grey};
  border-color: ${({ $isValid, theme }) =>
    $isValid ? "none" : theme.colors.secondary};
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

const TextareaField = styled.textarea`
  ${inputStyle};
  box-sizing: border-box;
  outline: none;
  resize: none;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;

const HelpText = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 8px;
  line-height: 1.1em;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 4px;
`;

export const Textarea = ({
  id,
  label,
  name,
  value = "",
  handleChange = () => {},
  placeholder,
  autoComplete = "none",
  errorMessage,
  validate,

  fontSize,
  minHeight,
  padding,

  lfz,
  lc,
  lmb,
}) => {
  return (
    <>
      {label && (
        <Label htmlFor={id} lfz={lfz} lmb={lmb} lc={lc}>
          {label}
        </Label>
      )}

      <TextareaField
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        $isValid={!(errorMessage && validate)}
        fontSize={fontSize}
        minHeight={minHeight}
        padding={padding}
        maxLength={120}
      />
      {errorMessage && validate ? <HelpText>{errorMessage}</HelpText> : <></>}
    </>
  );
};
