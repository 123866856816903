import React from "react";
import styled from "styled-components";
import Section from "components/section/Section";

import error_img from "images/error.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
    max-width: 420px;
    @media ${({theme}) => theme.media.sm} {
        max-width: 320px;
    }
`;

const MainSection = () => {
  return (
    <Section padding={"60px 0 10px"}>
      <Wrapper>
        <Image src={error_img}/>
      </Wrapper>
    </Section>
  );
};

export default MainSection;
