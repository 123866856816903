import styled, { css } from "styled-components";

const Label = styled.label`
  display: block;
  font-weight: 400;
  font-size: ${({ lfz }) => lfz || "16px"};
  margin-bottom: ${({ lmb }) => lmb || "5px"};
  color: ${({ lc, theme }) => lc || theme.colors.white};
`;

const inputStyle = css`
  box-sizing: border-box;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || "45px"};
  padding: ${({ padding }) => padding || "6px 20px;"};
  background-color: ${({ theme }) => theme.colors.violetDark};
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  color: ${({ theme }) => theme.colors.grey};
  margin: 0;
  border-color: ${({ $isValid, theme }) =>
    $isValid ? "none" : theme.colors.secondary};
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

const StyledInput = styled.input`
  ${inputStyle}
`;

const HelpText = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 8px;
  line-height: 1.1em;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 4px;
`;

export const Input = ({
  id,
  type = "text",
  label,
  name,
  value = "",
  handleChange = () => {},
  placeholder,
  autoComplete = "none",
  errorMessage,
  validate,

  minHeight,
  padding,
  fontSize,
  textAlign,

  lfz,
  lc,
  lmb,
}) => {
  return (
    <>
      {label && (
        <Label htmlFor={id} lfz={lfz} lmb={lmb} lc={lc}>
          {label}
        </Label>
      )}

      <StyledInput
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        $isValid={!(errorMessage && validate)}
        minHeight={minHeight}
        padding={padding}
        fontSize={fontSize}
        textAlign={textAlign}
      />
      {errorMessage && validate ? <HelpText>{errorMessage}</HelpText> : <></>}
    </>
  );
};
