import React from "react";
import styled, { css } from "styled-components";
import TermsItem from "../termsItem/TermsItem";
import Icon from "components/icon/Icon";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

const Wrapper = styled.div`
  overflow: hidden;
`;

const StyledSwiper = styled(Swiper)`
  overflow: visible;
  padding: 0 20px;
`;

const StyledSlide = styled(SwiperSlide)``;

const Controls = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0 auto;
`;

const arrowStyles = css`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.media.md} {
    width: 40px;
    height: 40px;
  }
`;

const Arrow = styled.div`
  ${arrowStyles}
`;

const Fraction = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const TermsList = ({
  terms,
  navigationClass = "terms",
  fractionClass = "terms",
}) => {
  const onBeforeInit = (swiper) => {
    if (typeof swiper.params.navigation !== "boolean") {
      const navigation = swiper.params.navigation;

      navigation.prevEl = `.prev-${navigationClass}`;
      navigation.nextEl = `.next-${navigationClass}`;
    }
    if (typeof swiper.params.pagination !== "boolean") {
      const fraction = swiper.params.pagination;

      fraction.el = `.fraction-${fractionClass}`;
      fraction.type = "fraction";
    }
  };
  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <StyledSwiper
        modules={[Navigation, Pagination]}
        onBeforeInit={onBeforeInit}
        navigation
        pagination
        spaceBetween={140}
      >
        {terms.length > 0 &&
          terms.map((term) => (
            <StyledSlide key={Math.random() + term.id}>
              <TermsItem term={term} />
            </StyledSlide>
          ))}
        <Controls>
          <Arrow className={`prev-${navigationClass} prev`}>
            <Icon icon='arrow-left' color='#ffffff' size='18px' />
          </Arrow>
          <Fraction className={`fraction-${fractionClass}`} />
          <Arrow className={`next-${navigationClass} prev`}>
            <Icon icon='arrow-right' color='#ffffff' size='18px' />
          </Arrow>
        </Controls>
      </StyledSwiper>
    </Wrapper>
  );
};

export default TermsList;
